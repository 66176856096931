// General

$black: #303233; // dark charcoal
$gray-dark: #DFDFDF; // gainsboro
$gray-light: #EBEBEB; // bright gray

$text-gray: #667085;
$banner-gray: #F9FAFB;

$brand-orange: #ED7F22; // princeton orange
$brand-blue: #2B3D6B;

$title-blue: #273139;
$theme-blue: #1A3867;

$white: #FFFFFF;