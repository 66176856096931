.app {
    header {
        background:$theme-blue;
        color:$white;

        .wrapper {
            display:flex;
            flex-direction:row;
            align-items:center;
            gap: 13px;
            height:4rem;

            span {
                font-size:1.3rem;
                font-family: 'Poppins';
                text-transform:uppercase;
            }
            
        }
    }

    nav {
        display:none;

        .container {
            @include media(">=desktop") {
                padding-left:16.6rem;
            }
        }

        @include media(">=desktop") {
            display:block;
            margin:3.2rem 0;
        }
    }

    main {
        margin-top:6.4rem;

        .container {
            @include media(">=desktop") {
                padding-left:16.6rem;
            }
        }

        .wrapper {

            article {
                h1 {
                    margin-bottom:1.6rem;
                }

                margin-bottom:3.2rem;

                @include media(">=desktop") {
                    margin-bottom:0;
                }
            }

            aside {
                figure {
                    display:none;
                    width:100%;

                    svg {
                        width:100%;

                        @include media(">=desktop") {
                        }
                    }

                    @include media(">=tablet") {
                        display:block;
                    }

                    @include media(">=desktop") {
                        width:445px;
                    }
                }
            }

            @include media(">=tablet") {
                display:flex;
                flex-direction:row;
                margin-bottom:5.1rem;
                align-items:center;
            }

            @include media(">=desktop") {
                margin-bottom:12.1rem;

            }
        }
    }

    .banner {
        .wrapper {
            background:$banner-gray;
            padding:4.8rem 3.2rem;
            margin-bottom:1.6rem;
            border-radius:16px;
            display:flex;
            flex-direction:column;
            align-items:center;
            justify-content:center;

            .content {
                

                h2 {
                    a {
                        font-weight:inherit;
                    }

                    @include media(">=desktop") {
                        line-height:40px;
                    }
                }

                @include media(">=desktop") {
                    flex:1;
                }
            }

            .actions {
                margin:3.2rem 0 0;
                display:flex;
                align-items:center;
                justify-content:center;

                a {
                    display:block;
                    background:$brand-blue;
                    color:$white;
                    font-weight:600;
                    font-size:1.8rem;
                    line-height:22px;
                    padding:1.3rem 1.6rem;
                    border-radius:8px;
                    width:fit-content;

                    &:hover {
                        background:#4D79DC;
                        text-decoration:none;
                    }

                    @include media(">=desktop") {
                        font-size:16px;
                        padding:0.8rem 1rem;
                    }
                }

                @include media(">=desktop") {
                    margin:0;
                }
            }

            @include media(">=tablet") {
            }

            @include media(">=desktop") {
                flex-direction:row;
                padding:6.4rem;
                margin:0 3.2rem 6.4rem;
                gap:17.7rem;
            }
        }
    }

    footer {
        background:$theme-blue;
        color:$white;

        .container {
            @include media(">=desktop") {
                padding-left:16.6rem;
            }
        }

        .wrapper {
            display:flex;
            flex-direction:row;
            align-items:center;
            gap:16px;
            height:88px;

            span {
                display:block;
                text-transform:uppercase;
                font-size:8px;
                font-family:'Raleway';
                letter-spacing:2px;
            }
        }
    }
}