// Breakpoints to be used with include-media
// Example Css: http://include-media.com/#features

$mobile: 320px;
$tablet: 768px;
$desktop: 1280px;
$max-site-width: 1920px;

$container-width: 1440px;
$cw: $container-width;

$breakpoints: (
  'mobile': $mobile,
  'tablet': $tablet,
  'desktop': $desktop,
  'max-site-width': $max-site-width
);

$site-basis: 1440;
$site-basis-mobile: 375;
$site-basis-widescreen: 1800;