

hr {
  border: 0;
  border-bottom: 1px solid rgba(0,0,0,0.15);
  height: 0;
  padding: 0;
  margin: 60px 0;
}

body, html {
  position: relative;
}

html {
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

.max-container {
  margin: 0 auto;
  max-width: $max-site-width;
}

.container {
  margin: 0 auto;
  max-width: 144rem;
  padding: 0 1.6rem;

  @include media(">=desktop") {

  }
}