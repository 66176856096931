$base-font: "Inter", sans-serif;

// Scale all the things
:root {
  font-size: 62.5%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, p, a, h1, h2, h3, h4, h5, h6,
small, em, strong, input, button {
  font-family: $base-font;
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Headers
h1, h2 {
  display: block;
  margin: 0;
  font-weight: 600;
  line-height: 32px;
  color:$title-blue;

  &:first-child {
    margin-top: 0;
  }

  a {
    color: inherit;
  }

  @include media(">=tablet") {
    line-height:48px;
  }

  @include media(">=desktop") {
    line-height:72px;
  }
}

h1, .h1 { @include fluid-type(24px, 60px, 375px, 1280px); }
h2, .h2 { @include fluid-type(24px, 32px, 375px, 1280px); }

// Generic Styles
strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

a {
  text-decoration: none;
  color:inherit;
  transition:all 0.25s ease-in-out;

  &:hover {
    text-decoration:underline;
  }
}

p {
  //font-size:1.6rem;
  color:$text-gray;

  @include fluid-type(16px, 20px, 375px, 1280px);

  &:not(:last-of-type) {
    margin-bottom:2rem;
  }

  @include media(">=desktop") {
   // font-size:2rem;
  }
}