// Flex Classes
.flex {
  display: flex;

  &.wrap {
    flex-wrap: wrap;
  }

  &.j-sb {
    justify-content: space-between;
  }

  &.j-c {
    justify-content: center;
  }

  &.a-c {
    align-items: center;
  }

  &.center {
    align-items: center;
    justify-content: center;
  }
}
